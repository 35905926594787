import React from 'react';
import Layout from '../components/layout';
import Contactform from '../components/contactform/contactform';

const Contact = () => {
  return (
    <Layout pageTitle='Contact Page'>
      <Contactform />
    </Layout>
  );
};

export default Contact;
